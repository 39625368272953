import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { MutationResultPair} from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { FormDataItem } from "types/form";
import { getErrorMessage } from "./helpers/handleRequestError";

export interface UpdateUserDatailsData {
  formData: FormDataItem[];
}

export default function useUserUpdateDetails(): MutationResultPair<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateUserDatailsData,
  unknown
> {
  return useMutation(
    data => {
      return axios.post("/api/update-user", data).catch(err => {
        throw new Error(getErrorMessage(err.response));
      });
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("currentUser");
      }
    }
  );
}
