import VerifyEmailModal from "components/admin/VerifyEmailModal";
import { Alert } from "components/Alert";
import useCurrentUser from "hooks/useCurrentUser";
import useSendVerificationEmail from "hooks/useSendVerificationEmail";
import { useState } from "react";

/**
 * Renders a component that displays a message for email verification.
 */
export const EmailVerificationMessage = () => {
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);

  const { data: user } = useCurrentUser();

  const [sendVerificationEmail] = useSendVerificationEmail();

  function handleVerifyEmail() {
    setIsVerifyEmailModalOpen(true);
    sendVerificationEmail();
  }

  // Only show the message if the user is a primary admin and their email is not verified.
  if (
    (user && user?.isPrimaryAdmin && user.isEmailVerified) ||
    !user?.isPrimaryAdmin ||
    !user
  ) {
    return null;
  }

  return (
    <>
      <div className="sticky border-b border-yellow-300 bg-yellow-50 sm:px-4">
        <Alert className="mx-auto flex max-w-7xl rounded-none sm:pl-2 sm:pr-6 xl:px-6">
          Any created activities will not be publicly visible until your email
          address has been verified.{" "}
          <a
            className="cursor-pointer underline hover:text-yellow-600"
            onClick={handleVerifyEmail}
          >
            Click here
          </a>{" "}
          to verify your email address.
        </Alert>
      </div>
      <VerifyEmailModal
        emailAddress={user?.email}
        isOpen={isVerifyEmailModalOpen}
        setIsOpen={setIsVerifyEmailModalOpen}
      />
    </>
  );
};
