import type { QueryResult} from "react-query";
import { useQuery, queryCache } from "react-query";
import type { AxiosResponse } from "axios";
import axios from "axios";
import type { UserBooking } from "types/model/basket";
import { BOOKING_ADJUSTED_MESSAGE } from "helpers/messages";

interface GetBasketResponse {
  basket: UserBooking;
  isAdjusted: boolean;
}

const getBasket = async (): Promise<UserBooking> => {
  const response: AxiosResponse<GetBasketResponse> =
    await axios.get("/api/basket");

  if (response.data.isAdjusted) {
    window.alert(BOOKING_ADJUSTED_MESSAGE);
  }

  return response.data.basket;
};

export default function useBasket(): QueryResult<UserBooking> {
  return useQuery(["basket"], getBasket);
}
