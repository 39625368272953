import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { QueryResult} from "react-query";
import { useQuery } from "react-query";
import type { ErrorResponse } from "types";
import type { Agreement } from "types/model/booking-agreement";
import handleRequestError from "./helpers/handleRequestError";

export const getAllAgreements = async (
  _: unknown,
  includeDisabled?: boolean
): Promise<Agreement[]> => {
  const response: AxiosResponse<Agreement[]> = await axios.get(
    `/api/agreements${includeDisabled ? "&includeDisabled=true" : ""}`
  );
  return response.data;
};

export default function useAllAgreements(
  includeDisabled = false
): QueryResult<Agreement[], AxiosError<ErrorResponse>> {
  return useQuery(["allAgreements", includeDisabled], getAllAgreements, {
    onError: error => {
      handleRequestError(error);
    }
  });
}
